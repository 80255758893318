import BaseLayout from "@components/BaseLayout"
import Hero from "@components/Hero"
import Midia from "@components/Midia"
import SEO from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"

function MidiaPage({ data }) {
  const {
    metatag,
    articles: { nodes: articles },
    allMarkdownRemark: { edges: midias },
  } = data

  const midiaList = midias.map(midia => midia.node.frontmatter)
  const articlesList = articles.map(article => article.frontmatter)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )

  return (
    <BaseLayout
      isDark
      hero={<Hero title="midia.hero.title" subtitle="midia.hero.subtitle" />}
    >
      <SEO {...metatags} />
      <Midia articlesList={articlesList} midiaList={midiaList} />
    </BaseLayout>
  )
}

export const query = graphql`
  query MidiaList($language: String!) {
    metatag: markdownRemark(
      fileAbsolutePath: { glob: "**/content/metatags/x-apps-na-midia.md" }
    ) {
      frontmatter {
        languages {
          language
          keywords
          title
          description
        }
      }
    }
    articles: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/articles/*.md" } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          link
          description
          image {
            publicURL
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/midias/*.md" }
        frontmatter: {
          languages: { elemMatch: { language: { eq: $language } } }
        }
      }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            languages {
              language
              description
              title
            }
            youtubeVideoId
            videoStart
            image {
              childImageSharp {
                fluid(maxWidth: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MidiaPage
