import Translate from "@components/Translate"
import Img from "gatsby-image"
import get from "lodash/get"
import React, { Fragment, useRef } from "react"
import * as Style from "./Midia.styles"
import YoutubePlayer from "@components/YoutubePlayer"

function playFullscreen(iframe) {
  var requestFullScreen =
    iframe.requestFullScreen ||
    iframe.mozRequestFullScreen ||
    iframe.webkitRequestFullScreen
  if (requestFullScreen) {
    requestFullScreen.bind(iframe)()
  }
}

function Midia({ midiaList, articlesList }) {
  const playersRef = useRef({})

  return (
    <Fragment>
      <Style.Wrapper>
        <Style.MidiaWrapper>
          {midiaList.map((midia, index) => {
            const { title, description } = midia.languages.find(
              lang => lang.language === process.env.GATSBY_LANGUAGE
            )
            return (
              <Style.CardVideo
                key={index}
                onClick={() => get(playersRef.current[index], "playVideo")}
              >
                <YoutubePlayer
                  videoId={midia.youtubeVideoId}
                  opts={{
                    height: "200",
                    width: "100%",
                    playerVars: {
                      start: midia.videoStart || 0,
                    },
                  }}
                  onReady={event => (playersRef.current[index] = event.target)}
                  onPlay={() => {
                    playFullscreen(playersRef.current[index].f)
                  }}
                />
                <Style.CardContent>
                  <Style.CardImage>
                    {midia.image && (
                      <Img fluid={midia.image.childImageSharp.fluid} />
                    )}
                  </Style.CardImage>
                  <Style.CardInformation>
                    <Style.CardTitle>{title}</Style.CardTitle>
                    <Style.CardDescription>{description}</Style.CardDescription>
                  </Style.CardInformation>
                </Style.CardContent>
              </Style.CardVideo>
            )
          })}
        </Style.MidiaWrapper>
      </Style.Wrapper>
      <Style.ArticleContent>
        <Style.MidiaWrapper articles>
          {articlesList.map((midia, index) => (
            <Style.ArticleWrapper key={index}>
              <Style.ArticleImage>
                <Style.ImgLogo src={midia.image?.publicURL} />
              </Style.ArticleImage>
              <Style.ArticleTitle>{midia.title}</Style.ArticleTitle>
              <Style.ArticleLink link={midia.link}>
                <Translate id="midia.seeMore" />
              </Style.ArticleLink>
            </Style.ArticleWrapper>
          ))}
        </Style.MidiaWrapper>
      </Style.ArticleContent>
    </Fragment>
  )
}

export default Midia
